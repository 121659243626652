<template>
  <en-dialog :model-value="modelValue" width="80%" title="保单列表" @close="$emit('update:model-value', false)">
    <template #operation>
      <button-ajax link :method="operation.export.click">导出</button-ajax>
    </template>

    <tabs-maintain
      v-model="tabs.active"
      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['POLSTAT']) }"
      :props="{ label: 'message', value: 'code' }"
      :method="table.get"
    ></tabs-maintain>

    <table-dynamic
      :height="600"
      code="PLC"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      show-summary
      :summary="table.summary.data"
      :config="table.config"
    >
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      operation: {
        children: {
          export: {
            async click() {
              return this.ajax('GET /enocloud/policy/export', this.table.$ajaxParams)
            }
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/policy/query',
            summary: 'GET /enocloud/policy/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: this.tabs.active }
            }
          }
        },
        config: {
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          CUSTOMER_NAME: { header: { filter: { type: 'text', field: 'customerName' } } },
          PLATE_NO: { header: { filter: { type: 'text', field: 'plateNo' } } },
          COMMERCIAL_COMPANY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'commercialInsuranceCompanyId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value, type: 'I', rsexp: 'id,name', pagingEnabled: false })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          COMPULSORY_COMPANY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'commercialInsuranceCompanyId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value, type: 'I', rsexp: 'id,name', pagingEnabled: false })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          PREPARED_DATETIME: { header: { filter: { type: 'date', field: ['preparedStartDate', 'preparedEndDate'], props: { type: 'daterange' } } } },
          SALESMAN_NAME: { header: { filter: { type: 'text', field: 'salesman' } } },
          COMPULSORY_START_DATE: {
            header: { filter: { type: 'date', field: ['compulsoryStartDateFrom', 'compulsoryStartDateTo'], props: { type: 'daterange' } } }
          },
          COMPULSORY_END_DATE: {
            header: { filter: { type: 'date', field: ['compulsoryEndDateFrom', 'compulsoryEndDateTo'], props: { type: 'daterange' } } }
          },
          COMMERCIAL_START_DATE: {
            header: { filter: { type: 'date', field: ['commercialStartDateFrom', 'commercialStartDateTo'], props: { type: 'daterange' } } }
          },
          COMMERCIAL_END_DATE: {
            header: { filter: { type: 'date', field: ['commercialEndDateFrom', 'commercialEndDateTo'], props: { type: 'daterange' } } }
          },
          VEHICLE_RECORD_DATE: {
            header: { filter: { type: 'date', field: ['vehicle.recordDateStart', 'vehicle.recordDateEnd'], props: { type: 'daterange' } } }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
